import * as React from "react";
import { Form, Container, Breadcrumb } from "../../components";
import Seo from "../../components/Seo";

const Contact = () => {
  return (
    <Container>
      <Seo
        title="お問い合わせ"
        description="TKCホールディングへのお問い合わせはこちらかお願い致します。内容がまとまっていなくても、お気軽にお問い合わせください。お話をヒアリングさせていただき、やりたいことをどうやって実現可能か、整理するところからお任せいただけます。"
      />
      <Form />
      <Breadcrumb currentPage="Contact" currentSlug="/contact" />
    </Container>
  );
};

export default Contact;
